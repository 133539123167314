<template>
    <!-- 登陆块儿组件   -->
    <div>
        <div class="fx-r fx-ac fx-bc logincontainer">
            <div class="fx-h fx-ac fx-bc loginbg" style="font-size: 40px;"></div>
            <div class="fx-r fx-ac fx-bc" style="width: 38%;">
              <div class="fx-h fx-ac fx-bc">
                  <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="80px">
                  <el-form-item style="">
                      <span slot="label">
                        <div class="fx-r fx-ac fx-bc" style="width: 290px;font-size: 28px;margin-bottom: 20px;color: #696969;">注册账号</div>
                      </span>
                  </el-form-item>
                  <el-form-item label="手机号" prop="mobile">
                      <el-input type="tel" v-model="ruleForm.mobile"></el-input>
                  </el-form-item>
                  <el-form-item label="验证码" prop="smsCode">
                      <el-input v-model="ruleForm.smsCode" autocomplete="off" style="width: 55%;"></el-input>
                      <button id="btnSendCode" class="codebtn" @click="sendCode" :disabled="codebtnDisable">发送验证码</button>
                  </el-form-item>
                  <el-form-item class="fx-r fx-ac fx-bc">
                      <el-button type="primary" @click="submitForm('ruleForm')" style="width: 160px;height: 45px;">注册</el-button>
                      <el-button type="primary" @click="changesta" style="width: 160px;height: 45px;" v-if="0">测试改变按钮状态</el-button>
                  </el-form-item>
                  </el-form>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCode , Reg } from '@/api/reg';

export default {
  name: 'LoginView',
  data() {
    return {
      codebtnDisable:false,
      ruleForm: {
        mobile: '',
        smsCode: '',
        wxcode:''
      },
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 6, max: 11, message: '⻓度在 6 到 11 位', trigger: 'blur' },
          {pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/, message: "请输⼊正确的⼿机号码", trigger:"blur"}
        ],
        smsCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 4, max: 4, message: '验证码格式错误', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    changesta(){
      if(this.codebtnDisable==false){
        this.codebtnDisable=true;
      }else{
        this.codebtnDisable=false;
      }
      
      // console.log(document.getElementById("btnSendCode"))
    },
    sendCode() {
      let that = this;
      getCode({ "mobile": that.ruleForm.mobile, "type": 0 }).then(res => {
        if (res.data.code == 200) {
          setTimeout(function () {
            // var phoneDom = document.querySelector('#phone');
            var btnSendCode = document.querySelector("#btnSendCode");
            var curCount = 60;
            var count = 60; // 间隔函数，1秒执行
            var InterVal; // timer变量，控制时间

            function SetTime() {
              if (curCount == 0) {
                window.clearInterval(InterVal); //停止计时器
                // that.codebtnDisable=false;
                that.changesta()
                // btnSendCode.removeAttribute("disabled"); //启用按钮
                btnSendCode.innerText = "重新发送";
              } else {
                curCount--;
                btnSendCode.innerText = curCount + "秒再获取";
              }
            }
            curCount = count;
            //设置button效果，开始计时
            that.changesta();
            btnSendCode.innerText = curCount + "秒再获取";
            InterVal = window.setInterval(SetTime, 1000); //启动计时器，1秒执行一次
          }, 1000);

          that.$message({
            message: '验证码已发送！',
            type: 'success'
          });
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          Reg(this.ruleForm).then(res=>{
            if(res.data.code==200){
                this.$message({
                message: '注册成功！',
                type: 'success'
              });
            }
            // this.ruleForm.mobile='';
            // this.ruleForm.smsCode='';
          })
        } else {
          this.$message.error('注册失败！');
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>
.logincontainer {
  height: 400px;
  width: 1200px;
  background: #F8F8FF;
  /* background: white; */
}

.loginbg {
  height: 100%;
  width: 60%;
  margin-right: 2%;
  background-image: url('https://img.jldsq.com/pro/2024/05/2308d5ab2a9ee54443bea0dfb297e25888.png');
  background-size: 100% 100%;
}

.codebtn{
  cursor: pointer;
  width: 37%;
    height: 35px;
    margin-left: 8%;
    background-color: #409eff;
    border: 0px;
    color: white;
    border-radius: 5px;
  }
  
  .codebtn:disabled {
    background-color: #D3D3D3;
    color:#F5F5F5;
    cursor: default;
  }
</style>