import axios from "axios";
// 导入进度条的库和样式文件
import NProgress from 'nprogress';
// eslint-disable-next-line no-unused-vars
import { MessageBox, Message } from 'element-ui'
// import Cookies from "js-cookie";
import 'nprogress/nprogress.css';

// 是否显示重新登录
export let isRelogin = { show: false };

export function request(config) {
  const instance = axios.create({
    baseURL: 'https://api.jldsq.com',
    timeout: 10000
  });


  // 拦截请求。请求在到达服务器之前会首先调用这个函数对请求做一些预处理
  // 在这里展示进度条
  instance.interceptors.request.use(config => {
    NProgress.start();
    // let token = Cookies.get("token");
    // 为请求头对象，添加Token验证的Authorization字段
    // config.headers["Authorization"] = token;
    return config;
  });

  // 拦截响应。在这里隐藏进度条
  instance.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    // const code = res.data.code || 200;
    NProgress.done();
    // if (code === 401) {
    //   if (!isRelogin.show) {
    //     isRelogin.show = true;
    //     MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', { confirmButtonText: '重新登录', cancelButtonText: '取消', type: 'warning' }).then(() => {
    //       isRelogin.show = false;
    //       location.href = '/';
    //     }).catch(() => {
    //       isRelogin.show = false;
    //     });
    //   }
    // }
    return res;
  },
    error => {
      console.log('err' + error)
      let { message } = error;
      if (message == "Network Error") {
        message = "后端接口连接异常";
      } else if (message.includes("timeout")) {
        message = "系统接口请求超时";
      } else if (message.includes("Request failed with status code")) {
        message = "系统接口" + message.substr(message.length - 3) + "异常";
      }
      Message({ message: message, type: 'error', duration: 5 * 1000 })
      return Promise.reject(error)
    });

  return instance(config);
}

