import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ShoeView from '../views/ShoeView.vue'
import OtherView from '../views/OtherView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path:'/about',
    name:'about',
    component: AboutView
  },
  {
    path:'/shoe',
    name:'shoe',
    component: ShoeView
  },
  {
    path:'/other',
    name:'other',
    component: OtherView
  }
]

const router = new VueRouter({
  routes
})

export default router
