<template>
  <div class="fx-h fx-ac fx-bc">
    <!-- 导航栏 -->
    <NavBar />
    <!-- 第一部分 轮播+注册-->
    <div class="fx-h fx-ac fx-bc" style="width: 1200px;">
      <!-- 创建外部展示容器 -->
      <div class="banner-container">
        <div class="topimage">
          <div class="topimage-text">
            专业团队</div>
        </div>
      </div>
      <LoginView />
      <!-- 第二部分 -->
      <div class="fx-h fx-ac fx-bc" style="width: 1200px;margin-top: 10px;">
        <!-- 公司场景图 -->
        <div style="height: 100%;width: 100%;">
          <el-carousel :interval="4000" type="card" height="400px">
            <el-carousel-item v-for="item in imgUrl" :key="item.src">
              <img :src="item"/>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="fx-h fx-bc fx-ac" style="height: 500px;width: 100%;background-color: #F0F8FF;font-size: 40px;" v-if="0">占位图
        </div>
        <div class="" style="height: 500px;width: 100%;background-color: #fff;font-size: 40px;">
          <div class="fx-r fx-ac fx-bc" style="height: 100%;">
            <div class="fx-h" style="height: 15%;width: 100%;">公司地址</div>
            <!-- 地图 -->
            <div class="fx-h fx-ac fx-bc location"></div>
            <div class="fx-h" style="font-size: 20px;width: 40%;padding-left: 5%;">
              <div class="fx-h" style="margin-bottom: 100px;">
                <div>公司地址：</div>
                <div class="address-phone">浙江省台州市温岭市创业大厦2幢1607</div>
              </div>
              <div class="fx-h">
                <div>联系电话：</div>
                <div class="address-phone">0576-80686858</div>
              </div>
            </div>
          </div>
        </div>
        <div class="fx-h fx-bc fx-ac bottom">
          <div style="color: white;font-size: 28px;margin-bottom: 15px;">专注异地和大额支付问题，享受各种减免优惠</div>
          <div>
            <el-button type="primary" plain
              style="width: 200px;height: 50px;color: #B3315F;font-size: 24px;">立即入驻</el-button>
          </div>
        </div>
        <div class="fx-h fx-bc fx-ac" style="height: 500px;width: 100%;background-color: #EB7347;font-size: 40px;"
          v-if="0">占位图
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <BottomView />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import LoginView from '@/components/LoginView.vue'
import BottomView from '@/components/BottomView.vue'

export default {
  name: 'AboutView',
  components: {
    NavBar,
    LoginView,
    BottomView
  },
  data() {
    return {
      imgUrl:[
        require('../assets/images/公司图1.jpg'),
        require('../assets/images/公司图2.jpg'),
        require('../assets/images/公司图3.jpg')
      ]
    }
  },
  methods: {

  }
}
</script>

<style scoped>
.banner-container {
  width: 1200px;
  height: 400px;
  overflow: hidden;
  position: relative;
}

.topimage {
  background-image: url('https://img.jldsq.com/pro/2024/05/23f5e90dbdcf724648b840ec6d0e3605cd.png');
  background-size: 100% 98%;
  height: 100%;
  background-repeat: no-repeat;
}

.topimage-text{
  font-size: 60px;
    color: white;
    font-weight: 600;
    cursor: default;
    padding-top: 150px;
    padding-left: 200px;
  }

.el-carousel__item {
  margin: 0;
  height: 100%;
  width: 1200px;
}

/* 指示灯位置 */
::v-deep .el-carousel__indicators {
  left: unset;
  transform: unset;
  left: 45%;
}

/* 默认按钮的颜色大小等 */
::v-deep .el-carousel__button {
  width: 15px;
  height: 8px;
  border: none;
  border-radius: 20px;
  background: #FFEFD5;
  opacity: 1;
}

/* 选中图片的颜色 */
::v-deep .is-active .el-carousel__button {
  background: #FF7F50;
  opacity: 1;
}

.el-carousel__item img {
  margin: 0;
  background-size: 100% 100%;
  height: 400px;
  width: 600px;
}


.location {
  height: 85%;
  width: 55%;
  background-image: url('https://img.jldsq.com/pro/2024/05/24087d348078df48c7bbf6f219b16048f4.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.address-phone{
  margin-top: 5px;
  color: #696969;
  font-size: 16px;
}

.bottom{
  height: 250px;
    width: 100%;
    margin-top: 10px;
    background-image: linear-gradient( 135deg, #FFAA85 10%, #B3315F 100%);
    font-size: 40px;
}
</style>
