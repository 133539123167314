<template>
  <div class="fx-h fx-ac fx-bc">
    <!-- 导航栏 -->
    <NavBar />
    <!-- 第一部分 轮播+注册-->
    <div class="fx-h fx-ac fx-bc" style="width: 1200px;">
      <!-- 创建外部展示容器 -->
      <div class="banner-container">
        <div class="block">
          <el-carousel height="480px" arrow="never">
            <el-carousel-item v-for="item in imgUrl" :key="item.src">
              <img :src="item"/>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <LoginView />
      <!-- 第二部分 视觉差滚动 -->
      <!-- <div>
        <section class="secimage secimage-white"></section>
        <section class="secimage secimage2"></section>
        <section class="secimage secimage-white"></section>
        <section class="secimage secimage4"></section>
        <section class="secimage secimage-white"></section>
      </div> -->
      <div class="fx-h" style="width: 1200px;width: 100%;margin-top: 20px;">
        <div class="fx-h fx-bc fx-ac" style="height: 850px;width: 100%;">
          <div style="font-size: 32px;">核心功能</div>
          <div class="fx-h fx-ac fx-bc" style="margin-top: 40px;">
            <el-row :gutter="10" class="ico-row">
              <el-col :span="6">
                <div class="fx-h fx-ac fx-bc ico-container">
                  <div class="fx-h fx-ac fx-bc icoarea">
                    <div class="ico1"></div>
                  </div>
                  <div class="icotitle">平台兼容</div>
                  <div class="fx-r fx-ac fx-bc icotext">多平台可用，支持电脑、H5、微信、APP、小程序</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="fx-h fx-ac fx-bc ico-container">
                  <div class="fx-h fx-ac fx-bc icoarea">
                    <div class="ico2"></div>
                  </div>
                  <div class="icotitle">商品管理</div>
                  <div class="fx-r fx-ac fx-bc icotext">双单位、多规格多价格，联系商家议价，改价</div>
                </div>
              </el-col><el-col :span="6">
                <div class="fx-h fx-ac fx-bc ico-container">
                  <div class="fx-h fx-ac fx-bc icoarea">
                    <div class="ico3"></div>
                  </div>
                  <div class="icotitle">采购管理</div>
                  <div class="fx-r fx-ac fx-bc icotext">供应商一键采购，可填数量、箱数、款式，可议价改价</div>
                </div>
              </el-col><el-col :span="6">
                <div class="fx-h fx-ac fx-bc ico-container">
                  <div class="fx-h fx-ac fx-bc icoarea">
                    <div class="ico4"></div>
                  </div>
                  <div class="icotitle">库存管理</div>
                  <div class="fx-r fx-ac fx-bc icotext">管理库存，数量可见，上架时间，销售排行，方便补货</div>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="ico-row">
              <el-col :span="6">
                <div class="fx-h fx-ac fx-bc ico-container">
                  <div class="fx-h fx-ac fx-bc icoarea">
                    <div class="ico5"></div>
                  </div>
                  <div class="icotitle">客户管理</div>
                  <div class="fx-r fx-ac fx-bc icotext">可视客户列表，绑定客户，历史客户记录，消费金额，未结清提醒</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="fx-h fx-ac fx-bc ico-container">
                  <div class="fx-h fx-ac fx-bc icoarea">
                    <div class="ico6"></div>
                  </div>
                  <div class="icotitle">销售管理</div>
                  <div class="fx-r fx-ac fx-bc icotext">用户自行下单，商家代为下单，分享订单，订单打印</div>
                </div>
              </el-col><el-col :span="6">
                <div class="fx-h fx-ac fx-bc ico-container">
                  <div class="fx-h fx-ac fx-bc icoarea">
                    <div class="ico7"></div>
                  </div>
                  <div class="icotitle">营销管理</div>
                  <div class="fx-r fx-ac fx-bc icotext">多种商品标签可设置，店铺主推、店铺热销、店铺新品，商品上下架</div>
                </div>
              </el-col><el-col :span="6">
                <div class="fx-h fx-ac fx-bc ico-container">
                  <div class="fx-h fx-ac fx-bc icoarea">
                    <div class="ico8"></div>
                  </div>
                  <div class="icotitle">资金管理</div>
                  <div class="fx-r fx-ac fx-bc icotext">销量显示，后台销售数据可视化，所有流水均有记录，不怕记乱</div>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="ico-row">
              <el-col :span="6">
                <div class="fx-h fx-ac fx-bc ico-container">
                  <div class="fx-h fx-ac fx-bc icoarea">
                    <div class="ico9"></div>
                  </div>
                  <div class="icotitle">数据分析</div>
                  <div class="fx-r fx-ac fx-bc icotext">后台多种数据统计，每天每月销量、订单量展示，专业数据分析</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="fx-h fx-ac fx-bc ico-container">
                  <div class="fx-h fx-ac fx-bc icoarea">
                    <div class="ico10"></div>
                  </div>
                  <div class="icotitle">代理运营</div>
                  <div class="fx-r fx-ac fx-bc icotext">专业代理商，帮助推流促销，增加销量，互惠互利</div>
                </div>
              </el-col><el-col :span="6">
                <div class="fx-h fx-ac fx-bc ico-container">
                  <div class="fx-h fx-ac fx-bc icoarea">
                    <div class="ico11"></div>
                  </div>
                  <div class="icotitle">一码通行</div>
                  <div class="fx-r fx-ac fx-bc icotext">扫描店铺码即可开始购买，使用简单方便，全平台一码通行</div>
                </div>
              </el-col><el-col :span="6">
                <div class="fx-h fx-ac fx-bc ico-container">
                  <div class="fx-h fx-ac fx-bc icoarea">
                    <div class="ico12"></div>
                  </div>
                  <div class="icotitle">专业合作</div>
                  <div class="fx-r fx-ac fx-bc icotext">联合多家银行战略合作，支持大额，优惠多多</div>
                </div>
              </el-col>
            </el-row>
            <div style="color: #A9A9A9;font-size: 18px;margin-top: 15px;margin-left: 50%;">*根据版本和用户级别等有所差异，部分功能需要额外配置</div>
          </div>
        </div>
        <div
          style="height: 600px;width: 100%;background-image: url('https://img.jldsq.com/pro/2024/05/226923d1ac079048c7bd9446e75700f8ef.png');background-size: 100% 100%;">
        </div>
        <div class="fx-r fx-ac fx-bc">
          <div
          style="height: 1800px;width: 82%;background-image: url('https://img.jldsq.com/pro/2024/05/2384d2f7d4f1714252aa085988a9ffd9ef.png');background-size: 100% 100%;">
          </div>
        </div>
        
        <div class="fx-h fx-bc fx-ac" style="height: 500px;width: 100%;background-color: #00CCFF;font-size: 40px;" v-if="0">占位图
        </div>
        <div class="fx-h fx-bc fx-ac" style="height: 500px;width: 100%;background-color: #84AF9B;font-size: 40px;" v-if="0">占位图
        </div>
        <div class="fx-h fx-bc fx-ac" style="height: 500px;width: 100%;background-color: #EB7347;font-size: 40px;" v-if="0">占位图
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <BottomView />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import LoginView from '@/components/LoginView.vue'
import BottomView from '@/components/BottomView.vue'

export default {
  name: 'HomeView',
  components: {
    NavBar,
    LoginView,
    BottomView
  },
  data() {
    return {
      imgUrl: [
        require('../assets/images/轮播1.png'),
        require('../assets/images/轮播2.png'),
        require('../assets/images/轮播3.png'),
        require('../assets/images/轮播4.png')
      ]
    }
  },
  methods: {

  }
}
</script>

<style scoped>
.banner-container {
  width: 1200px;
  height: 480px;
  overflow: hidden;
  position: relative;
}

.banner-container .banner-img-container {
  width: 6000px;
  height: 400px;
  overflow: hidden;
  position: absolute;
  /* 开启弹性盒，让图片横向排列 */
  display: flex;
  animation: run 10s ease infinite;
}

.banner-container .banner-img-container img {
  width: 1200px;
  height: 100%;
}

.el-carousel__item {
  margin: 0;
  height: 480px;
  width: 1200px;
}

/* 指示灯位置 */
::v-deep .el-carousel__indicators {
  left: unset;
  transform: unset;
  left: 45%;
}

/* 默认按钮的颜色大小等 */
::v-deep .el-carousel__button {
  width: 15px;
  height: 8px;
  border: none;
  border-radius: 20px;
  background: #FFFFFF;
  opacity: 1;
}

/* 选中图片的颜色 */
::v-deep .is-active .el-carousel__button {
  background: #14A5FE;
  opacity: 1;
}

.el-carousel__item img {
  margin: 0;
  background-size: 100% 100%;
  height: 480px;
  width: 1200px;
}

.ico-container {
  border-radius: 4px;
  height: 200px;
  width: 280px;
}

.icoarea {
  height: 80px;
  width: 80px;
  border-radius: 50px;
  /* border: #D3D3D3 1px solid; */
  box-shadow: 3px 2px 5px #D3D3D3
}

.ico-row{
  width: 88%;
  margin-bottom: 20px;
}

/* ico样式 */
.ico1 {
  height: 60%;
  width: 60%;
  background-image: url('https://img.jldsq.com/pro/2024/05/23c469b664927243e393e45235f13831bf.png');
  background-size: 100% 100%;
}
.ico2 {
  height: 70%;
  width: 70%;
  background-image: url('https://img.jldsq.com/pro/2024/05/239add12ab58ae43799c4b0abe3022ef0e.png');
  background-size: 100% 100%;
}
.ico3 {
  height: 60%;
  width: 60%;
  background-image: url('https://img.jldsq.com/pro/2024/05/2327219ef97b33444faaabdb6a38327643.png');
  background-size: 100% 100%;
}
.ico4 {
  height: 85%;
  width: 85%;
  background-image: url('https://img.jldsq.com/pro/2024/05/23aa925c303d884da6b7b84d73fd28ddf1.png');
  background-size: 100% 100%;
}
.ico5 {
  height: 60%;
  width: 60%;
  background-image: url('https://img.jldsq.com/pro/2024/05/232616b69c4e0a446a9a009f8b27acbe8d.png');
  background-size: 100% 100%;
}
.ico6 {
  height: 60%;
  width: 60%;
  background-image: url('https://img.jldsq.com/pro/2024/05/23bf423068a81147c3b2461d4ec9fa3d2f.png');
  background-size: 100% 100%;
}
.ico7 {
  height: 60%;
  width: 60%;
  background-image: url('https://img.jldsq.com/pro/2024/05/235bde295f958b4ec1bb6776f1a2103d64.png');
  background-size: 100% 100%;
}
.ico8 {
  height: 60%;
  width: 60%;
  background-image: url('https://img.jldsq.com/pro/2024/05/23e2232b0883684b12804e2e65c52ccb19.png');
  background-size: 100% 100%;
}
.ico9 {
  height: 60%;
  width: 60%;
  background-image: url('https://img.jldsq.com/pro/2024/05/2326bcb38ec5d84aa8868ad49d5eb3d15d.png');
  background-size: 100% 100%;
}
.ico10 {
  height: 60%;
  width: 60%;
  background-image: url('https://img.jldsq.com/pro/2024/05/2353164df43c734baebad5810f2b56d70d.png');
  background-size: 100% 100%;
}
.ico11 {
  height: 60%;
  width: 60%;
  background-image: url('https://img.jldsq.com/pro/2024/05/23333caddd6b724a6abca08c970ac70599.png');
  background-size: 100% 100%;
}
.ico12 {
  height: 60%;
  width: 60%;
  background-image: url('https://img.jldsq.com/pro/2024/05/23f2272c4b424b4031af13bbcdc3538e3d.png');
  background-size: 100% 100%;
}

.icotitle {
  font-size: 20px;
  color: black;
  margin-top: 10px;
  font-weight: 500;
}

.icotext {
  color: #A9A9A9;
  font-size: 16px;
  margin-top: 20px;
  width: 180px;
  height: 45px;
}

/* section {
  height: 50vh;
  width: 1200px;
  background: rgba(0, 0, 0, .7);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20vh;
}

.secimage {
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
}

.secimage1 {
  background-image: url('https://img.jldsq.com/pro/2024/05/2119042a82140b42b5914ec63a86bf0bbf.png');
}

.secimage2 {
  background-image: url('https://img.jldsq.com/pro/2024/05/2125b60116f8b946c89a73bcfac33fe624.png');
}

.secimage3 {
  background-image: url('https://img.jldsq.com/pro/2024/05/21c8a87636e5ce4d8895e344d0baa6ebbc.png');
}

.secimage4 {
  background-image: url('https://img.jldsq.com/pro/2024/05/21dd4e098b5d3e494f85eee1d423c0a277.png');
}

.secimage-white{
  background:white;
} */
</style>
