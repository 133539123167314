import {request} from "./request";

//发送短信验证码
export function getCode(data) {
    return request({
      url: '/h5/register/smscode',
      method: 'post',
      data:data
    })
}

//注册
export function Reg(data) {
    return request({
      url: '/h5/register/mobile',
      method: 'post',
      data:data
    })
}