<template>
    <div class="fx-h fx-ac fx-bc">
        <!-- 导航栏 -->
        <NavBar />
        <!-- 第一部分 图片+注册-->
        <div class="fx-h fx-ac fx-bc" style="width: 1200px;">
            <!-- 创建外部展示容器 -->
            <div class="banner-container">
                <div class="block fx-h fx-ac fx-bc" style="background-color: #FF8C00;height: 100%;font-size: 40px;">
                    其他
                </div>
            </div>
            <LoginView />
            <!-- 第二部分 -->
            <div class="fx-h fx-ac fx-bc" style="width: 1200px;width: 100%;">
                <div class="fx-h fx-bc fx-ac"
                    style="height: 500px;width: 100%;background-color: 	#4169E1;font-size: 40px;">占位图</div>
                <div class="fx-h fx-bc fx-ac"
                    style="height: 500px;width: 100%;background-color: #F0F8FF;font-size: 40px;">占位图</div>
                <div class="fx-h fx-bc fx-ac"
                    style="height: 500px;width: 100%;background-color: #00CCFF;font-size: 40px;">占位图</div>
                <div class="fx-h fx-bc fx-ac"
                    style="height: 500px;width: 100%;background-color: #84AF9B;font-size: 40px;">占位图</div>
                <div class="fx-h fx-bc fx-ac"
                    style="height: 500px;width: 100%;background-color: #EB7347;font-size: 40px;">占位图</div>
            </div>
        </div>
        <!-- 底部 -->
        <BottomView/>
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import LoginView from '@/components/LoginView.vue'
import BottomView from '@/components/BottomView.vue'

export default {
    name: 'OtherView',
    components: {
        NavBar,
        LoginView,
        BottomView
    },
    data() {
        return {
        }
    },
    methods: {

    }
}
</script>

<style scoped>
.banner-container {
    width: 1200px;
    height: 400px;
    overflow: hidden;
    position: relative;
}

.banner-container .banner-img-container {
    width: 6000px;
    height: 400px;
    overflow: hidden;
    position: absolute;
    /* 开启弹性盒，让图片横向排列 */
    display: flex;
    animation: run 10s ease infinite;
}

.banner-container .banner-img-container img {
    width: 1200px;
    height: 100%;
}

.el-carousel__item {
    margin: 0;
    height: 480px;
    width: 1200px;
}

/* 指示灯位置 */
::v-deep .el-carousel__indicators {
    left: unset;
    transform: unset;
    left: 45%;
}

/* 默认按钮的颜色大小等 */
::v-deep .el-carousel__button {
    width: 36px;
    height: 8px;
    border: none;
    border-radius: 20px;
    background: #FFFFFF;
    opacity: 1;
}

/* 选中图片的颜色 */
::v-deep .is-active .el-carousel__button {
    background: #14A5FE;
    opacity: 1;
}

.el-carousel__item img {
    margin: 0;
    background-size: 100% 100%;
    height: 480px;
    width: 1200px;
}
</style>