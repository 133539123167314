<template>
  <div class="fx-h fx-ac fx-bc">
    <div style="padding: 4px 0px;">
      <!-- 导航 -->
      <div style="">
        <div class="fx-r fx-ac fx-bc" style="height: 100px;width: 1200px;">
          <div class="fx-r fx-bc" style="height: 100%;width: 20%;margin-right: 10%;">
            <div class="logo"></div>
            <div class="logo-title">界联都市</div>
          </div>
          <div class="fx-h fx-ac fx-bc" style="height: 100%;width: 70%;">
            <div class="fx-r fx-ac fx-bc" style="height: 100%;margin-left: 400px;">
              <router-link to="/" class="link">首页</router-link>
              <!-- <div style="margin: 0px 10px;font-size: 22px;">行业方案</div> -->
              <div style="margin: 0px 10px;"  v-if="0">
                <el-dropdown style="cursor: pointer;" slot="dropdown">
                  <span class="el-dropdown-link" style="font-size: 18px;color: #000;"> 
                    行业方案
                    <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
                  </span>
                  <el-dropdown-menu slot="dropdown" class="dropdown-menu">
                    <router-link to="/shoe">
                      <el-dropdown-item>鞋业</el-dropdown-item>
                    </router-link>
                    <router-link to="/" v-if="0">
                      <el-dropdown-item disabled>加工</el-dropdown-item>
                    </router-link>
                    <router-link to="/other" v-if="0">
                      <el-dropdown-item>生活</el-dropdown-item>
                    </router-link>
                    <router-link to="/" v-if="0">
                      <el-dropdown-item divided>待补充</el-dropdown-item>
                    </router-link>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <router-link to="/about" class="link">关于我们</router-link>
              <div style="margin: 0px 10px;">
                <button class="downloadbtn" @click="toDownload">App下载</button>
                <button class="downloadbtn" @click="download" v-if="0">第二个按钮</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  props: {

  },
  data() {
    return {

    }
  },
  methods: {
    toDownload() {
      window.open("http://xn--7st613cczhpxq.com/#/");
    },
    download() {
      this.$message({
        message: '点击成功！',
        type: 'success'
      });
    }
  }
}
</script>


<style>
/* 修改背景色以及border颜色*/
.dropdown-menu {
  /* background-color: #445081;
  border-color: #445081; */
  width: 120px;
}
/* 修改小三角的样式 这里自定义类名与第三方组件的类中间一定不要有空格,不然更改无效!!!*/
/* .dropdown-menu.el-popper[x-placement^="bottom"] .popper__arrow::after,
.dropdown-menu.el-popper[x-placement^="bottom"] .popper__arrow {
  border-bottom-color: #445081;
} */
.dropdown-menu.el-dropdown-menu--medium
  .el-dropdown-menu__item.el-dropdown-menu__item--divided:before {
  height: 0;
}
/* 修改每一项的字体颜色,这里因为是里层的类,所以中间要加上空格!!!*/
.dropdown-menu .el-dropdown-menu__item--divided {
  border-top-color: #000;
}
.dropdown-menu .el-dropdown-menu__item {
  color: #000;
}
.dropdown-menu .el-dropdown-menu__item:hover {
  background-color: #1E90FF;
  color:#000;
}
</style>

<style scoped>
.router-link-active{
  text-decoration: none;
  color: #000;
  font-size: 18px;
}

a{
  text-decoration: none;
  color: #000;
  font-size: 18px;
}

.link{
  margin: 0px 10px;
}

.logo {
  background-image: url('https://img.jldsq.com/pro/2024/05/22a00b3594b51b4785b1283c021080ce14.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 80%;
  width: 30%;
  border-radius:10px ;
  padding-right: 1px;
  margin-left: 5px;
}

.logo-title{
  margin-left: 20px;
  color: #000;
  font-size: 30px;
  font-style: o;
  font-family: STXihei, "华文细黑", "Microsoft YaHei", "微软雅黑";
}

.downloadbtn {
  cursor: pointer;
  height: 45px;
  width: 120px;
  background-color: chocolate;
  margin: 0px 8px;
  border: 0px;
  border-radius: 5px;
  color: white;
  font-size: 16px;
}
</style>
